import React from 'react';

function Icons(props) {
  return (
    <div className="d-flex flex-column bd-highlight mb-3 icon-container">
      <div className="p-2 bd-highlight d-flex position-relative icon">
        <img
          src={props.icon.image}
          className="position-absolute top-50 start-50 translate-middle"
          alt={props.icon.name}
        ></img>
      </div>
      {props.icon.name && <p className="p-2 bd-highlight first-letter">{props.icon.name}</p>}
      <div className="p-2 bd-highlight">{props.icon.description}</div>
    </div>
  );
}

export default Icons;
