import React, { useState, useEffect } from 'react';

function Banners(props) {
  const [slides, setSlides] = useState(null);
  useEffect(() => {
    setSlides(null);
    setSlides(props.slides);
    return () => {
      setSlides(null);
    };
  }, [props.slides]);

  return (
    // slides !== null && (
    <div
      className={`container-fluid full-size position-relative ${props.component}`}
      id={`banner-${props.component}`}
    >
      <div
        id={`carousel-${props.component}`}
        className="carousel slide"
        data-ride={`carousel-${props.component}`}
      >
        <div className="carousel-indicators">
          {slides &&
            slides.map((slide, i) => {
              return (
                <button
                  key={i}
                  data-target={`#carousel-${props.component}`}
                  // key={`${i}`}
                  type="button"
                  data-bs-target={`#carousel-${props.component}`}
                  data-bs-slide-to={`${i}`}
                  className="active"
                  aria-current="true"
                  aria-label={`Slide ${i + 1}`}
                ></button>
              );
            })}
        </div>
        <div className={`carousel-${props.component} carousel-inner`}>
          {slides &&
            slides.map((slide, i) => {
              return (
                <div className={`carousel-item ${i === 0 ? 'active' : ''}`} key={i}>
                  <img src={slide.image} className="d-block w-100" alt="..." />
                  <div className="carousel-caption d-md-block">
                    <p dangerouslySetInnerHTML={{ __html: slide.caption }}></p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
    // )
  );
}

export default Banners;
