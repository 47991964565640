import React, { useEffect, useState } from 'react';

const useValidation = (initialState, validate, fn) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [submitForm, setSubmitForm] = useState(false);

  useEffect(() => {
    if (submitForm) {
      const noErrors = Object.keys(errors).length === 0;

      if (noErrors) {
        fn(); //Fn es la función que se ejecuta en el componente
      }

      setSubmitForm(false);
    }
  }, [errors]);

  //Función que se ejecuta cnforme el usuario escribe algo

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  //Función que se ejecuta cnforme el usuario hace submit

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorValidation = validate(values);
    setErrors(errorValidation);
    setSubmitForm(true);
  };

  //Función que se ejecuta cuando se realiza el blur
  const handleBlur = () => {
    const erroresValidation = validate(values);
    setErrors(erroresValidation);
  };

  return {
    values,
    errors,
    submitForm,
    handleSubmit,
    handleChange,
    handleBlur,
  };
};

export default useValidation;
