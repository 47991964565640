export default function validarContact(valores) {
  let errores = {};
  const emailRegex = /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;

  // Validar email
  if (!valores.email || !valores.email.length) {
    errores.email = 'El correo es requerido';
  } else if (!emailRegex.test(valores.email)) {
    errores.email = 'El correo no es válido';
  }

  // Validar telefono
  if (!valores.phone) {
    errores.phone = 'El telefono es requerido';
  }

  // Validar mensaje
  if (!valores.message) {
    errores.message = 'Incluye un mensaje';
  }

  return errores;
}
