import React, { useEffect, useState } from 'react';

import close from '../../public/images/home/close.svg';

export default function AbogadosDetail(props) {
  const [abogado, setAbogadosDetail] = useState(props.abogado);
  useEffect(() => {
    setAbogadosDetail(() => {
      return props.abogado;
    });
  }, [props.abogado]);

  return (
    <div>
      {abogado && (
        <div
          className={
            abogado
              ? 'abogado-detail-container cover-background parallax open'
              : 'abogado-detail-container cover-background parallax'
          }
        >
          <div className="abogado-detail-indications">
            <div className="close-cover transparent">
              <img
                className="close_icon"
                src={close}
                alt="Close Icon"
                aria-hidden="true"
                onClick={props.selectService(null)}
              />
            </div>
            <div className="text-over-transparent container">
              <div className="row title">
                <div className="col-md-12">
                  <p className="position-absolute top-50 start-50 translate-middle titulo">
                    {abogado.title}
                  </p>
                </div>
              </div>
            </div>
            <div className="long-description text-over-transparent container">
              <div className="row title">
                <div className="col-md-12">
                  <p className="" dangerouslySetInnerHTML={{ __html: abogado.description }}></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
