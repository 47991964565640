import React from 'react';
import { NavLink } from 'react-router-dom';

import background from '../../public/images/abogados/12.jpg';
import logoWA from '../../public/images/home/logo_wa.png';
import logoFB from '../../public/images/home/logo_fb.png';
import logoIG from '../../public/images/home/logo_instagram.png';
import useValidation from '../hooks/useValidation';
import validarContact from '../validation/validarContact';
import clientAxios from '../config/axios';

const STATE_INITIAL = {
  email: '',
  phone: '',
  message: '',
};

function Footer(props) {
  const { values, errors, submitForm, handleSubmit, handleChange, handleBlur } = useValidation(
    STATE_INITIAL,
    validarContact,
    sendMessage,
  );

  const { email, phone, message } = values;

  async function sendMessage() {
    try {
      const respuesta = await clientAxios.post('/mail.php', values);
      console.log(respuesta);
    } catch (error) {
      console.log(error);
    }
    console.log('HACER POST...');
  }
  return (
    <>
      <footer
        className="container-fluid footer-full-size parallax"
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        {/* <a className="wapp-cta show-right-transition" to="#">
          <div className="row">
            <div className="col-md-3">
              <img src={logoWA} alt="Contáctanos via WA"></img>
            </div>
            <div className="col-md-9">
              <p className="position-absolute top-50 start-50 translate-middle">
                Habla con uno de nuestros ejecutivos
              </p>
            </div>
          </div>
        </a> */}
        <div className="container text-over-transparent">
          <div className="row title">
            <div className="col-md-12">
              <p className="position-absolute top-50 start-50 translate-middle">¡CONTÁCTANOS!</p>
              <p className="position-absolute top-50 start-50 translate-middle">
                TE APOYAMOS EN TU PROCESO LEGAL
              </p>
            </div>
          </div>
          <div className="row maps">
            <div className="col-md-6">
              <div className="card mb-3">
                <div className="img-fluid map-background">
                  <div
                    style={{
                      backgroundImage: `url('https://maps.googleapis.com/maps/api/staticmap?center=Camino%20al%20Desierto%20de%20los%20Leones%204048&zoom=13&size=800x300&maptype=roadmap%20&markers=color:red%7Clabel:C%7C19.3470025,-99.2055581%20&key=AIzaSyBTvM4TdsuuI27azD6reV_rYcZThyslH6U')`,
                    }}
                  ></div>
                </div>
                <div className="card-body transparent">
                  <p className="card-text">Calzada al Desierto de los Leones, #4048, Pisos 1 y 2</p>
                  <p className="card-text">San Ángel, 01060, Álvaro Obregón</p>
                  <p className="card-text">México CDMX</p>
                </div>
              </div>
            </div>
            <div className="col-md-6" id="contacto">
              <form className="form" onSubmit={handleSubmit} noValidate>
                <input
                  className="form-control form-control-lg transparent"
                  type="text"
                  placeholder="CORREO"
                  aria-label=".form-control-lg example"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && <p>{errors.email}</p>}
                <input
                  className="form-control form-control-lg transparent"
                  type="text"
                  placeholder="NÚMERO"
                  aria-label=".form-control-lg example"
                  name="phone"
                  value={phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.phone && <p>{errors.phone}</p>}
                <textarea
                  className="form-control form-control-lg transparent"
                  aria-label="Mensaje"
                  name="message"
                  placeholder="MENSAJE"
                  value={message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></textarea>
                {errors.message && <p>{errors.message}</p>}
                <button type="submit" className="btn btn-lg btn-dark float-end">
                  ENVIAR
                </button>
              </form>
            </div>
          </div>
        </div>
      </footer>
      <div className="row rrss justify-content-evenly">
        <NavLink exact className="col-md-1 position-relative" to="/">
          <img
            src={logoFB}
            alt="Síguenos en Facebook"
            className="fb position-absolute top-50 start-50 translate-middle"
          ></img>
        </NavLink>
        <NavLink exact className="col-md-1 position-relative" to="/">
          <img
            src={logoIG}
            alt="Síguenos en Instagram"
            className="insta position-absolute top-50 start-50 translate-middle"
          ></img>
        </NavLink>
        <div className="col-md-4 offset-md-6 disclaimer position-relative">
          <p className="position-absolute top-50 end-0 translate-middle">Copyright® 2022 CNR</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
