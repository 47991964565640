import React, { useState } from 'react';

import experiencia from '../../public/images/home/experiencia.png';
import etica from '../../public/images/home/etica.png';
import eficiencia from '../../public/images/home/eficiencia.png';
import litigio from '../../public/images/nuevas/litigio.jpeg';
import contratacion from '../../public/images/home/contratacion.jpg';
import colectivo from '../../public/images/nuevas/colectivo.jpeg';
import seguridadsocial from '../../public/images/nuevas/seguridadsocial.jpeg';
import derecho_laboral from '../../public/images/nuevas/derecho_laboral.jpeg';
import subcontratacion from '../../public/images/nuevas/subcontratacion.jpeg';
import administrativo from '../../public/images/nuevas/administrativo.jpeg';
import consultoria from '../../public/images/nuevas/consultoria.jpeg';
import auditoria from '../../public/images/nuevas/auditoria.jpeg';

import HomeServiceDetail from './home-services-detail';
import HomeServices from './home-services';
import Icons from './icons';
import Banners from './banners';

function HomeContent(props) {
  const [service, setService] = useState(null);
  const icons = [
    {
      name: 'EXPERIENCIA',
      image: experiencia,
      description: `Se ha consolidado como una prestigiada firma de asesores jurídicos en materia laboral
      y seguridad social.`,
    },
    {
      name: 'ÉTICA',
      image: etica,
      description: `Nuestro equipo sobresale por su profesionalismo y por su apego a las reglas de ética,
      mostrando todo el respeto a sus clientes.`,
    },
    {
      name: 'EFICIENCIA',
      image: eficiencia,
      description: `Mas de 29 años nos respaldan con abogados que tienen amplia experiencia trabajando
      como asesores laborales externos.`,
    },
  ];
  const selectService = function (service) {
    return function () {
      if (service) {
        setService(service);
        document.body.style.overflow = 'hidden';
      } else {
        setService(null);
        document.body.style.overflow = 'unset';
      }
    };
  };
  return (
    <>
      <Banners slides={props.banners} component="home" />
      <HomeServiceDetail service={service} selectService={selectService}></HomeServiceDetail>
      <div className="container container-md home-content position-relative">
        <div className="row history container-md">
          <div className="col-md-12">
            <p>
              <b className="first-letter">SE FUNDÓ EL 1° DE AGOSTO DE 1992</b> y está concebido como
              una firma legal boutique especializada en materia laboral y seguridad social, que está
              compuesta por abogados que tienen amplia experiencia trabajando como asesores
              laborales externos.
            </p>
          </div>
        </div>
        <div className="row history-items container-md">
          {icons &&
            icons.map((icon, i) => {
              return (
                <div className="col-md-4" key={i}>
                  <Icons icon={icon}></Icons>
                </div>
              );
            })}
        </div>
        <div className="services">
          <div className="row">
            <div className="col-md-8">
              <HomeServices
                selectService={selectService}
                content={{
                  title: 'DERECHO LABORAL',
                  component: 'trainings',
                  image: derecho_laboral,
                  short_description: `El objetivo del Despacho es proporcionar Asesoría Laboral Preventiva y contribuir para que la Empresa cuente con los elementos y documentos obligatorios establecidos en la Legislación Laboral y sus Reglamentos, y por consiguiente en caso de una demanda laboral, evitar que se pudiera llegar a condenar a la Empresa al pago de alguna prestación, por no contar con los documentos necesarios para acreditar que esta fue pagada o bien que la reclamación es improcedente.`,
                  description: `El objetivo del Despacho es proporcionar Asesoría Laboral Preventiva y contribuir para que la Empresa cuente con los elementos y documentos obligatorios establecidos en la Legislación Laboral y sus Reglamentos, y por consiguiente en caso de una demanda laboral, evitar que se pudiera llegar a condenar a la Empresa al pago de alguna prestación, por no contar con los documentos necesarios para acreditar que esta fue pagada o bien que la reclamación es improcedente.
                <br><br>De igual forma en caso de una revisión por parte de una autoridad del trabajo, contar con los elementos necesarios para evitar la imposición de una multa por no acreditar el cumplimento de las obligaciones que imponen las leyes.`,
                }}
              />
            </div>
            <div className="col-md-4">
              <HomeServices
                selectService={selectService}
                content={{
                  title: 'SEGURIDAD SOCIAL',
                  component: 'trainings',
                  image: seguridadsocial,
                  short_description: `El Despacho también tiene como objeto asesorar Empresas en lo relativo al cumplimiento de las obligaciones establecidas en la ley del Seguro Social, así como en la del Infonavit y sus reglamentos.`,
                  description: `El Despacho también tiene como objeto asesorar Empresas en lo relativo al cumplimiento de las obligaciones establecidas en la ley del Seguro Social, así como en la del Infonavit y sus reglamentos.
                <br><br>
                Para tal efecto se ofrece la práctica de una Auditoría para determinar si la empresa se encuentra cubriendo correctamente las cuotas obrero-patronales y/o aportaciones, revisando si están debidamente afiliados los trabajadores, así como si está debidamente integrado el salario. Por lo que respecta al Seguro de Riesgos de Trabajo, si se encuentran clasificados en la clase correcta, de acuerdo a las actividades de la Empresa y si la prima es la adecuada, tomando en cuenta la siniestralidad.
                <br><br>
                También es importante señalar que se atiende lo relativo a la clasificación de los Riesgos de Trabajo como tales y la presentación de la declaración anual de la determinación de la prima del Seguro de Riesgos de Trabajo.
                <br><br>
                Asimismo, se interviene en el desahogo de las Auditorías practicadas por las citadas Instituciones ya sean voluntarias o inducidas en base al Programa de Autocorrección. 
                <br><br>
                Por último, también se presentan los medios de defensa hasta sus últimas consecuencias en contra de cualquier diferencia en el pago de las cuotas o aportaciones, que llegaran a determinar cualquiera de los citados Institutos y que estas resultaran improcedentes, o bien, en la celebración de convenios para el pago en parcialidades de cualquier diferencia en caso de que esta resultara procedente.
                `,
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <HomeServices
                selectService={selectService}
                content={{
                  title: 'LITIGIO',
                  component: 'trainings',
                  image: litigio,
                  short_description: `Los servicios que se prestan en relación con litigios individuales son, entre otros, los siguientes...`,
                  description: `Los servicios que se prestan en relación con litigios individuales son, entre otros, los siguientes:
                  <ul>
                  <li>Celebración de pláticas conciliatorias con la finalidad de terminar con el litigio. </li>
                  <li>Análisis de la demanda laboral presentada en contra de la empresa, con el objeto de determinar la estrategia legal apropiada para el juicio. En caso de que el juicio ya hubiere iniciado, se analizará la defensa planteada y se preparará un reporte completo sobre la defensa interpuesta por firmas distintas a Cervantes Nieto, Rebolledo y Abogados, S.C.</li>
                  <li>Preparación documentos para acreditar la personalidad a nombre de los demandados, lo cual incluirá el análisis del instrumento notarial para determinar qué persona tiene facultad para otorgar poderes, de igual forma se revisará que reúna los requisitos de la Ley General de Sociedades Mercantiles y la Ley Federal del Trabajo. </li>
                  <li>Solicitud y análisis de información y documentos que pudieran ser ofrecidos como prueba, incluyendo el análisis de testigos y opiniones de peritos.</li>
                  <li>Preparación de los escritos de contestación a la demanda, ofrecimiento de pruebas y cualquier otro escrito que sea necesario durante el procedimiento laboral ante la Junta de Conciliación y Arbitraje y/o Juzgados en materia laboral ya sean objeciones de pruebas, incidentes, regularizaciones, entre otros. </li>
                  <li>Atención de la audiencia preliminar ante el centro de Conciliación ya sea local o federal.</li>
                  <li>Atención de las audiencias que se lleven a cabo en el litigio laboral.</li>
                  <li>Atención desahogo de pruebas, tales como la confesional, preparando los pliegos de posiciones para el desahogo de las confesionales del trabajador; preparar y asesorar a las personas que vayan a desahogar dicha prueba como representantes de la empresa y/o para Hechos Propios, de igual forma atenderíamos la prueba Testimonial y de igual manera se repreguntaría a los testigos ofrecidos por la parte actora y se formularía el incidente de tachas a los mismos y en su caso, también se contestaría el incidente de tachas que llegaran a formular a nuestros testigos.</li>
                  <li>Asimismo, se intervendría en las demás pruebas que se llegaran a ofrecer como sería la inspección, acudiendo a la empresa para desahogar la misma y exhibir los documentos u objetos necesarios para el desahogo de dicha prueba; así como las periciales, sugiriendo a los peritos de la materia de que se trate, para coordinar el desahogo y proporcionar los elementos necesarios para tal efecto y la presentación del dictamen correspondiente y por lo que respecta al perito de los trabajadores, se analizaría el dictamen correspondiente y se formularían las objeciones pertinentes.</li>
                  <li>De igual forma, se intervendría y daría seguimiento a las pruebas ofrecidas por las partes como son los informes, cotejos, etc., formulando los alegatos correspondientes.</li>
                  <li>Se vigilaría el asunto hasta el momento en que se dicte el laudo correspondiente y nos notifiquemos del mismo.</li>
                  <li>Elaboración de cualquier escrito que sea necesario durante el procedimiento laboral ante la Junta de Conciliación y Arbitraje. </li>
                  <li>Atención de reuniones y/o conferencias telefónicas para preparar la defensa, preparación de pruebas o informar sobre el estado procesal del litigio, así como la preparación de los reportes o informes que requiera la empresa para su control interno.</li>
                  </ul>
                  En virtud de que un litigio laboral puede generar pérdidas importantes para nuestros clientes, nuestra práctica de litigio realiza sus actividades en cercanía con la empresa, proporcionando información útil y real sobre la contingencia económica que el litigio representa, proporcionando un consejo jurídico adecuado, con el objeto de que la empresa pueda tomar una decisión informada y adecuada sobre el conflicto. La comunicación es un elemento fundamental para nuestra práctica de litigio, por lo que constantemente se proporciona información sobre los litigios que atendemos. 
                  
                  La práctica de litigio se rige por altos estándares de calidad y ética, caracterizándose por el cumplimiento de reglas nacionales e internacionales en cuanto a la relación con entidades gubernamentales.`,
                }}
              />
            </div>
            <div className="col-md-4">
              <HomeServices
                selectService={selectService}
                content={{
                  title: 'SUBCONTRATACIÓN',
                  component: 'trainings',
                  image: subcontratacion,
                  short_description: `Verificación de cumplimiento de obligaciones derivados de relaciones especializadas.`,
                  description: `
                  Análisis de la subcontratación.
                  <br><br>
                  Celebración del contrato especializado.
                  <br><br>
                  Registro ante el REPSE de servicio especializado.
                  <br><br>
                  Sustitución patronal.
                  <br><br>
                  Verificación de cumplimiento de obligaciones derivados de relaciones especializadas.
                  <br><br>
                  `,
                }}
              />
            </div>
            <div className="col-md-4">
              <HomeServices
                selectService={selectService}
                content={{
                  title: 'COLECTIVO',
                  component: 'trainings',
                  image: colectivo,
                  short_description: `En caso de ser necesario, se interviene en cuestiones de carácter colectivo como es en la firma de un Contrato Colectivo de Trabajo.`,
                  description: `En caso de ser necesario, se interviene en cuestiones de carácter colectivo como es en la firma de un Contrato Colectivo de Trabajo.
                <br><br>
                De igual forma la atención de negociaciones derivabas de la revisión de contratos Colectivos de Trabajo ya sean salariales o integrales. 
                <br><br>
                Atención de emplazamientos a huelga por firma y/o violaciones al contrato colectivo. 
                <br><br>
                Atención de demandas de titularidad por Sindicatos terceros que aleguen representación sindical.
                <br><br>
                Atención de conflictos colectivos de naturaleza económica.
                <br><br>
                Elaboración de toda clase de documentos que se desprendan de una relación colectiva de trabajo tales como reglamento interior de trabajo, la integración de su comisión y cualquier otra que sea necesaria. 
                <br><br>
                Negación, celebración y revisión del contrato Ley.
                <br><br>
                Asesoría en materia de suspensión o terminación colectiva de la relación de trabajo.
                <br><br>
                Con motivo de la reforma a la Ley Federal de Trabajo, se implementan estrategias para legitimar los contratos colectivos que se encuentran depositados y aprobados ante las juntas de Conciliación y Arbitraje, o bien en aquellos casos en que se celebre un nuevo contrato colectivo de trabajo con el Sindicato.
                `,
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <HomeServices
                selectService={selectService}
                content={{
                  title: 'CONSULTORÍA',
                  component: 'trainings',
                  image: consultoria,
                  short_description: `Los servicios del área de práctica de Consultoría son diversos y amplios, abarcando todas las operaciones laborales de una empresa como...`,
                  description: `Los servicios del área de práctica de Consultoría son diversos y amplios, abarcando todas las operaciones laborales de una empresa:
                  <ul>
                  <li>Consultas del día a día en materia laboral (individual y colectiva) y de seguridad social, relacionadas con cualquier tema laboral que pudiera surgir con motivo de la operación de la empresa.</li>
                  <li>Consultoría relacionada con investigaciones laborales y terminación de las relaciones de trabajo, incluyendo la preparación de documentos, actas administrativas, cartas de amonestación, entre otros.</li>
                  <li>Asesoría y elaboración para la rescisión de contratos individuales de trabajo.</li>
                  <li>Revisión, preparación, modificación y/o implementación de políticas internas de personal sobre cualquier tema laboral interno de la empresa tales como comportamiento, vestimenta, herramientas de trabajo, entre otras.</li>
                  <li>Consultoría relacionada con prestaciones laborales, incluyendo su implementación, modificación o eliminación.</li>
                  <li>Revisión, preparación, modificación y/o implementación de políticas, planes o programas de compensación variable.</li>
                  <li>Preparación y revisión de cartas ofertas de trabajo, contratos individuales de trabajo, convenios de confidencialidad, entre otros.</li>
                  <li>Consultoría relacionada con acciones para evitar discriminación, acoso sexual o laboral, así como para garantizar el cumplimiento de la normatividad en materia de privacidad de datos.</li>
                  <li>Consultoría en relación con las Comisiones Mixtas que deben existir al interior de la empresa, incluyendo su constitución y funcionamiento.</li>
                  <li>En general, cualquier actividad relacionada con la operación de empresa y sus aspectos laborales.</li>
                  <li>Análisis e implementación de subcontratación en servicios especializados.</li>
                  <li>Asesoría para la obtención del registro en el padrón de la Secretaria de Trabajo y Previsión Social.</li>
                  <li>Asesoría para la migración de trabajadores de una empresa a otra.</li>
                  <li>Implementación correcta de la Reglamentación del Teletrabajo o Trabajo a distancia.</li>
                  <li>Asesoría para la determinación del pago del PTU. </li>
                  <li>Implementación de los requisitos y obligaciones derivados de la NOM-35.</li>
                  <li>Asesoría para la reanudación de las actividades económicas por motivo de suspensión por emergencia sanitaria.</li>
                  <li>Asesoramiento y seguimiento relacionado con inspecciones de trabajo en materia de condiciones generales de trabajo y capacitación y adiestramiento. </li>
                  <li>Diseño de estrategias para la reestructura o cierres de empresa.</li>
                  <li>Preparación de la documentación relativa a la confidencialidad de los trabajadores con motivo del desempeño de su trabajo.</li>
                  </ul>
                  Nuestra práctica de Consultoría Laboral, a través de una asesoría que no solo considera los aspectos legales del tema, sino también los aspectos prácticos y de negocios, ayuda a la implementación de soluciones para prevenir y evitar contingencias económicas mediante la creación de estrategias legales adecuadas para cada cliente.
                  <br>
                  Bajo un modelo de trabajo inteligente, colaborativo y aprovechando el uso de tecnologías de la información, esta área ha desarrollado modelos de servicios adecuados que permiten una respuesta rápida y precisa. Por regla general de calidad, todas las opiniones o trabajos solicitados se envían al cliente en un máximo de 24 horas y, en caso de requerirse un mayor tiempo, oportunamente se informa al cliente el tiempo que se requerirá, el cual debe cumplirse siempre. `,
                }}
              />
            </div>
            <div className="col-md-4">
              <HomeServices
                selectService={selectService}
                content={{
                  title: 'ADMINISTRATIVO',
                  component: 'trainings',
                  image: administrativo,
                  short_description: `De igual manera, se atiende cualquier trámite administrativo que pudiera derivar tanto en la Secretaría de Trabajo y Previsión Social, como en la Dirección General de Trabajo y Previsión Social del ...`,
                  description: `De igual manera, se atiende cualquier trámite administrativo que pudiera derivar tanto en la Secretaría de Trabajo y Previsión Social, como en la Dirección General de Trabajo y Previsión Social del Departamento del Distrito Federal o cualquier autoridad de trabajo, atender los citatorios ante la Procuraduría de la Defensa del Trabajo.
                <br>
                Intervenir en el desahogo de las inspecciones que se practiquen por parte de dichas autoridades para verificar el cumplimiento de las políticas en materia laboral y en caso de ser necesario, combatir las multas que se llegaran a imponer por dicho motivo.
                <br>
                Se asesora a las empresas en los trámites de una fusión como es el pasar el personal de una empresa a otra, dando por terminada la relación de trabajo anterior, así como la relación con el Sindicato, negociar nuevas condiciones de trabajo y cualquier trámite que derive de lo anterior.`,
                }}
              />
            </div>
            <div className="col-md-4">
              <HomeServices
                selectService={selectService}
                content={{
                  title: 'AUDITORÍA',
                  component: 'trainings',
                  image: auditoria,
                  short_description: `Un aspecto fundamental de los servicios que presta el Despacho, es la práctica de una Auditoria en materia laboral sobre los documentos que utiliza la Empresa para regular la relación obrero patronal ...`,
                  description: `Un aspecto fundamental de los servicios que presta el Despacho, es la práctica de una Auditoria en materia laboral sobre los documentos que utiliza la Empresa para regular la relación obrero patronal, como son contratos de trabajo tanto individuales como colectivos, recibos de pago de salarios, vacaciones, prima sobre las mismas, aguinaldo y demás prestaciones que se otorguen, controles de asistencia, reglamento interior de trabajo, cuadro general de antigüedades, comisiones mixtas de seguridad e higiene, capacitación y adiestramiento, reglamento interior de trabajo,  fomento actividades deportivas y culturales, entre otros.`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeContent;
