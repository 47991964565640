import React, { useEffect, useState } from 'react';

function HomeServices(props) {
  const [content, setContent] = useState({
    image: null,
    short_description: '',
    description: '',
    title: '',
  });
  useEffect(() => {
    setContent(props.content);
  }, [props.content]);
  return (
    <>
      <div
        className={`cover-background ${content.component}`}
        style={{
          backgroundImage: `url(${content.image})`,
        }}
      >
        <div className="translate-up-transition transparent position-relative more-info">
          <div className="container">
            <h4 className="">{content.title}</h4>
            {!!content.short_description && (
              <p
                className="short-description"
                dangerouslySetInnerHTML={{ __html: content.short_description || '' }}
              ></p>
            )}
            {content.quote && (
              <p className="quote" dangerouslySetInnerHTML={{ __html: content.quote || '' }}></p>
            )}
            {content.description && (
              <button
                type="button"
                className="btn btn-lg btn-dark show-more"
                onClick={props.selectService(content)}
              >
                LEER MÁS
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeServices;
