import React from 'react';

import acuerdos_normativos from '../../public/images/capacitaciones/acuerdos_normativos.png';
import alternativas from '../../public/images/capacitaciones/alternativas.png';
import amparo from '../../public/images/capacitaciones/amparo.png';
import aspectos_elementales from '../../public/images/capacitaciones/aspectos_elementales.png';
import aspectos_laborales from '../../public/images/capacitaciones/aspectos_laborales.png';
// import capacitaciones from '../../public/images/capacitaciones/capacitaciones.pdf';
import derecho_corporativo from '../../public/images/capacitaciones/derecho_corporativo.png';
import empresas_prestadoras_de_servicios from '../../public/images/capacitaciones/empresas_prestadoras_de_servicios.png';
import estrategias_laborales from '../../public/images/capacitaciones/estrategias_laborales.png';
import evitar_problemas_laborales from '../../public/images/capacitaciones/evitar_problemas_laborales.png';
import generalidades from '../../public/images/capacitaciones/generalidades.png';
// import header from '../../public/images/capacitaciones/header.jpg';
import industria_de_la_construccion from '../../public/images/capacitaciones/industria_de_la_construccion.png';
import industria_hotelera from '../../public/images/capacitaciones/industria_hotelera.png';
import la_prevencion from '../../public/images/capacitaciones/la_prevencion.png';
import manejo_practico from '../../public/images/capacitaciones/manejo_practico.png';
import manejo_preventivo_laboral from '../../public/images/capacitaciones/manejo_preventivo_laboral.png';
import procesal_laboral from '../../public/images/capacitaciones/procesal_laboral.png';
import relaciones_laborales from '../../public/images/capacitaciones/relaciones_laborales.png';
import subcontratacion from '../../public/images/capacitaciones/subcontratacion.png';

import Banners from './banners';
import Icons from './icons';

function Capacitaciones(props) {
  const icons = [
    { name: null, image: acuerdos_normativos, description: 'Acuerdos normativos laborales.' },
    {
      name: null,
      image: alternativas,
      description: 'Alternativas de negociaciones laborales en tiempos difíciles.',
    },
    { name: null, image: amparo, description: 'El juicio de amparo en materia laboral.' },
    {
      name: null,
      image: aspectos_elementales,
      description: 'Aspectos elementales en el manejo de las relaciones obrero patronales.',
    },
    { name: null, image: aspectos_laborales, description: 'Aspectos laborales de las empresas.' },
    {
      name: null,
      image: derecho_corporativo,
      description: 'Derecho corporativo y económico, aspectos laborales de la empresa.',
    },
    {
      name: null,
      image: empresas_prestadoras_de_servicios,
      description: 'Las empresas prestadoras de servicios.',
    },
    {
      name: null,
      image: estrategias_laborales,
      description: 'Estrategias laborales.',
    },
    {
      name: null,
      image: evitar_problemas_laborales,
      description: 'Como prevenir y evitar problemas laborales.',
    },
    {
      name: null,
      image: generalidades,
      description: 'Generalidades prácticas del a Ley Federal del Trabajo',
    },
    {
      name: null,
      image: industria_de_la_construccion,
      description: 'El manejo preventivo laboral en la industria de la construcción.',
    },
    {
      name: null,
      image: industria_hotelera,
      description: 'El manejo preventivo laboral en la industria hotelera.',
    },
    {
      name: null,
      image: la_prevencion,
      description: 'La prevención práctica en las relaciones obrero patronales.',
    },
    {
      name: null,
      image: manejo_practico,
      description: 'Manejo práctico y efectivo del Contrato Individual de Trabajo.',
    },
    {
      name: null,
      image: manejo_preventivo_laboral,
      description: 'El manejo preventivo laboral en la industria restaurantera.',
    },
    { name: null, image: procesal_laboral, description: 'Derecho procesal laboral.' },
    {
      name: null,
      image: relaciones_laborales,
      description: 'Manejo de las relaciones laborales en época de crisis.',
    },
    {
      name: null,
      image: subcontratacion,
      description: 'La subcontratación, ventajas y desventajas.',
    },
  ];
  const iconsList = new Array(icons.length / 3).fill([]);
  let j = 0;
  for (let i = 0; i < icons.length; i++) {
    iconsList[j] = [...iconsList[j], icons[i]];
    if ((i + 1) % 3 === 0) {
      j++;
      continue;
    }
  }

  return (
    <>
      <Banners slides={props.banners} component="trainings" />
      <div className="container container-md home-content" id="icons">
        <div className="row history container-md">
          <div className="col-md-12">
            <p>
              Los miembros del despacho tienen <b>amplia experiencia en materia de capacitación</b>,
              ya que han impartido cursos y conferencias ante diversos Foros, Organismos y Empresas,
              sobre Derecho Laboral, Ley del Seguro Social, INFONAVIT y sus reglamentos.
            </p>
            <p className="text-start">
              Entre los cursos que se pueden impartir se encuentran los siguientes:
            </p>
          </div>
        </div>
        <div className="row history-items container-md">
          {icons &&
            iconsList.map((array, j) => {
              return (
                <div className="col-md-4" key={`icons-row-${j}`}>
                  {array &&
                    array.map((icon, i) => {
                      return <Icons icon={icon} key={`icons-${i}`}></Icons>;
                    })}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default Capacitaciones;
