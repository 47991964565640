import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import home1 from '../public/images/abogados/7.jpg';
// import home2 from '../public/images/abogados/9.jpg';
import home3 from '../public/images/abogados/6.jpg';
import home4 from '../public/images/nuevashome/89.jpg';
import home5 from '../public/images/nuevashome/63.jpg';
import capacitaciones from '../public/images/abogados/4.jpg';
// import abogadosBanner12 from '../public/images/abogados/12.jpg';
// import abogadosBanner11 from '../public/images/abogados/11.jpg';
// import abogadosBanner10 from '../public/images/abogados/10.jpg';
// import abogadosBanner9 from '../public/images/abogados/9.jpg';
// import abogadosBanner8 from '../public/images/abogados/8.jpg';
// import abogadosBanner7 from '../public/images/abogados/7.jpg';
// import abogadosBanner6 from '../public/images/abogados/6.jpg';
// import abogadosBanner5 from '../public/images/abogados/5.jpg';
// import abogadosBanner4 from '../public/images/abogados/4.jpg';
// import abogadosBanner3 from '../public/images/abogados/3.jpg';
import abogadosBanner2 from '../public/images/abogados/2.jpg';
// import abogadosBanner1 from '../public/images/abogados/1.jpg';
import abogadosBanner3 from '../public/images/bannerabogados/10.jpg';
import abogadosBanner4 from '../public/images/bannerabogados/15.jpg';
import abogadosBanner5 from '../public/images/bannerabogados/18.jpg';
import abogadosBanner6 from '../public/images/bannerabogados/21.jpg';
import abogadosBanner1 from '../public/images/bannerabogados/22.jpg';

import Capacitaciones from './components/capacitaciones';
import Abogados from './components/abogados';
import HomeContent from './components/home-content';
import Header from './components/heading';
import Footer from './components/footer';

import './styles/main.sass';

function App(props) {
  const bannersList = {
    home: [
      {
        image: home5,
        caption: `Se ha consolidado como una prestigiada firma de <b>asesores jurídicos</b> en
      materia de <b> Derecho del Trabajo y de la Seguridad Social</b>.`,
      },
      {
        image: home1,
        caption: `Se ha consolidado como una prestigiada firma de <b>asesores jurídicos</b> en
      materia de <b> Derecho del Trabajo y de la Seguridad Social</b>.`,
      },
      // {
      //   image: home2,
      //   caption: `Se ha consolidado como una prestigiada firma de <b>asesores jurídicos</b> en
      // materia de <b> Derecho del Trabajo y de la Seguridad Social</b>.`,
      // },
      {
        image: home3,
        caption: `Se ha consolidado como una prestigiada firma de <b>asesores jurídicos</b> en
      materia de <b> Derecho del Trabajo y de la Seguridad Social</b>.`,
      },
      {
        image: home4,
        caption: `Se ha consolidado como una prestigiada firma de <b>asesores jurídicos</b> en
      materia de <b> Derecho del Trabajo y de la Seguridad Social</b>.`,
      },
    ],
    capacitaciones: [
      {
        image: capacitaciones,
        caption: '',
      },
    ],
    abogados: [
      {
        image: abogadosBanner5,
        caption: '',
      },
      {
        image: abogadosBanner3,
        caption: '',
      },
      {
        image: abogadosBanner6,
        caption: '',
      },
      {
        image: abogadosBanner2,
        caption: '',
      },
      {
        image: abogadosBanner1,
        caption: '',
      },
      {
        image: abogadosBanner4,
        caption: '',
      },
    ],
  };
  const [banners] = useState(bannersList);
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/">
          <HomeContent banners={banners.home} />
        </Route>
        <Route path="/capacitaciones">
          <Capacitaciones banners={banners.capacitaciones} />
        </Route>
        <Route path="/abogados">
          <Abogados banners={banners.abogados} />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
