import React, { useState } from 'react';

import Abogado1 from '../../public/images/abogados/Abogado1.jpg';
import Abogado2 from '../../public/images/abogados/Abogado2.jpg';
import Abogado3 from '../../public/images/abogados/Abogado3.jpg';
import Abogado5 from '../../public/images/abogados/Abogado5.jpg';
import Abogado6 from '../../public/images/abogados/Abogado6.jpg';
import Abogado8 from '../../public/images/abogados/nuevo-abogado8.jpeg';
import Abogado9 from '../../public/images/abogados/nuevo-abogado9.jpeg';
import Abogado10 from '../../public/images/abogados/nuevo-abogado10.jpeg';
import Abogado11 from '../../public/images/abogados/nuevo-abogado11.jpeg';
import Consultor from '../../public/images/abogados/Consultor.jpg';
import socio2 from '../../public/images/abogados/socio2.jpg';
import socio1 from '../../public/images/abogados/socio1.jpg';
import socio3 from '../../public/images/abogados/Socio3.jpg';

import Banners from './banners';
import AbogadosDetail from './abogados-detail';
import HomeServices from './home-services';

function Abogados(props) {
  const [abogado, setAbogado] = useState(null);
  const selectAbogado = function (abogado) {
    return function () {
      if (abogado) {
        setAbogado(abogado);
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
      } else {
        setAbogado(null);
        document.body.style.overflow = 'unset';
        document.body.style.position = 'inherit';
      }
    };
  };
  return (
    <>
      <Banners slides={props.banners} component="lawyers" />
      <AbogadosDetail abogado={abogado} selectService={selectAbogado}></AbogadosDetail>
      <div className="container container-md home-content">
        <div className="services">
          <h1>SOCIOS</h1>
          <div className="row">
            <div className="col-md-4">
              <HomeServices
                selectService={selectAbogado}
                content={{
                  title: 'LIC. LILIA CERVANTES ARTEAGA',
                  image: socio2,
                  short_description: `Socio`,
                  description: `
                  <p>Abogada egresada de la Universidad Intercontinental, con amplia experiencia profesional consultoría laboral para el sector empresarial.</p>
                  <p>Formación y estudios:</p>
                  <ul>
                  <p>Licenciada en Derecho por la Universidad Intercontinental. </p>
                  </ul>
                  <p>A partir de septiembre del 2020 se incorporó como socio de la firma <b>Cervantes Nieto, Rebolledo y Abogados, S.C.</b></p>`,
                  quote: `"La vida es un juicio ¡Gánalo!"`,
                  component: 'lawyers',
                }}
              />
            </div>
            <div className="col-md-4">
              <HomeServices
                selectService={selectAbogado}
                content={{
                  title: 'DR. MARIO ALBERTO REBOLLEDO MARTÍNEZ',
                  image: socio3,
                  short_description: `Socio`,
                  description: `<ul>
                  <li>Licenciado en Derecho egresado por la Universidad Latinoamericana.</li>
                  <li>Maestría en Derecho del Trabajo por el Centro de Estudios Universitarios Emmanuel Kant.</li>
                  <li>Doctor en Derecho del Trabajo en el Centro de Estudios Universitarios Emmanuel Kant.</li>
                  <li>Socio del despacho Cervantes Nieto, Rebolledo y Abogados, S.C. desde hace 23 años,</li>
                  <li>Fue Profesor de la Universidad Panamericana campus Santa Fe en la especialidad de Derecho Procesal Laboral.</li>
                  <li>Fue Profesor del posgrado del Instituto de Posgrado en Derecho en la materia de Teoría del Derecho Laboral.</li>
                  Profesor en Diplomado de la Universidad Panamericana Campus Guadalajara en materia de derecho laboral, en la especialidad de: Derechos <li>Humanos en el Derecho del Trabajo.</li>
                  <li>Profesor de la Maestría en Derecho Laboral del Instituto de Investigaciones Jurídicas de Nayarit. </li>
                  <li>Expositor titular del Instituto Superior de Estudios Fiscales, A.C.</li>
                  <li>Expositor del Instituto de Capacitación Virtual</li>
                  <li>Miembro del consejo técnico de Ediciones Fiscales Isef, S.A.</li>
                  <li>Expositor de la Asociación de Mexicana de Entidades Financieras.</li>
                  <li>Conferencista en diversos foros sobre la materia de Derecho del Trabajo y de la Seguridad Social,</li>
                  <li>Ponente en la Universidad Intercontinental.</li>
                  <li>Conferencista en diversos foros sobre la materia laboral.</li>
                  <li>Más de 27 años de experiencia en materia de derecho laboral.</li>
                  <li>Autor del libro: <b>"Recomendaciones Laborales Prácticas para el Teletrabajo"</b>, editado por Ediciones Fiscales ISEF, S.A </li>
                  </ul>`,
                  quote: `"Para resolver, primero hay que entender."`,
                  component: 'lawyers',
                }}
              />
            </div>
            <div className="col-md-4">
              <HomeServices
                selectService={selectAbogado}
                content={{
                  title: 'LIC. HÉCTOR MANUEL CERVANTES ARTEAGA',
                  image: socio1,
                  short_description: `Socio`,
                  description: `
                  <p>Abogado egresado de la Universidad Anáhuac México Norte, con amplia experiencia profesional especializada en el Litigio, individual y colectivo, y asesoría laboral para el sector empresarial, representando intereses de compañías nacionales y multinacionales, organismos gubernamentales y administrados en forma descentralizada ante las autoridades del trabajo.</p>
                  <p>Formación y estudios:</p>
                  <ul>
                  <li>Licenciado en Derecho por la Universidad Anáhuac México Norte. </li>
                  <li>Mater Internacional en Derecho y Gestión Deportiva impartido por el Instituto Superior de Derecho y Economía, Madrid, España.</li>
                  <li>Expositor titular del Instituto Superior de Estudios Fiscales, A.C.</li>
                  <li>Miembro del consejo técnico de Ediciones Fiscales Isef, S.A.</li>
                  </ul>
                  <p>A partir de septiembre del 2019 se incorporó como socio de la firma <b>Cervantes Nieto, Rebolledo y Abogados, S.C.</b></p>`,
                  quote: `"En los punto de vista está la sabiduría."`,
                  component: 'lawyers',
                }}
              />
            </div>
          </div>
          <h1>CONSULTOR</h1>
          <div className="row">
            <div className="col-md-4">
              <HomeServices
                selectService={selectAbogado}
                content={{
                  title: 'LIC. HÉCTOR MANUEL CERVANTES NIETO',
                  image: Consultor,
                  short_description: `Consultor`,
                  description: `<li><b>Licenciado en Derecho</b>, egresado de la <b>Universidad Iberoamericana</b></li>
                  <li><b>Consultor del Despacho Cervantes Nieto, Abogados, S.C.</b>, Asesores en materia Laboral. Con una experiencia de <b>más de 40 años en el medio laboral</b>.</li>
                  <li><b>Conferencista</b> en diversos foros sobre la materia de Derecho del Trabajo y de la Seguridad Social, así como articulista en diversas revistas también sobre la materia laboral. </li>
                  <li>Expositor titular del Instituto Superior de Estudios Fiscales, A.C., en la materia de Derecho del Trabajo desde 1996.</li>
                  <li>
                    <b>Autor de los libros:</b>
                    <ul>
                    <li><b>"Aspectos Prácticos del Contrato Individual de Trabajo"</b></li>
                    <li>Análisis Integral y Comentarios Prácticos a las <b>"Reforma Laboral 2011"</b></li>
                    <li>"Análisis y Comentarios a la <b>Reforma Laboral 2019</b>" </li>
                    <li><b>"Implicaciones Laborales del COVID 19"</b>, editados por Ediciones Fiscales ISEF, S.A </li>
                    </ul>
                  </li>
                  <li>Fue Expositor de la División de Educación Continua de la Facultad de Derecho de la <b>Universidad Nacional Autónoma de México</b>, para funcionarios de la Junta Federal de Conciliación y Arbitraje. </li>
                  <li><b>Profesor en diversos Diplomados de la Universidad Panamericana Campus Guadalajara</b>, en materia de derecho laboral, en las especialidades de: Obligaciones y Contratos, Derecho Constitucional, Derecho del Trabajo y Derecho Corporativo.</li>
                  <li>Ex Profesor en la Universidad Panamericana CDMX de la materia el <b>Procedimiento Laboral</b></li>
                  <li><b>Miembro de la Barra Mexicana Colegio de Abogados, A.C.</b>, ex subcoordinador y coordinador en la Comisión Derecho del Trabajo y de la Seguridad Social y ex consejero de la misma.</li>`,
                  quote: '"¡Actitud Mental Positiva!"',
                  component: 'lawyers',
                }}
              />
            </div>
          </div>

          <h1>ABOGADOS</h1>

          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <HomeServices
                selectService={selectAbogado}
                content={{
                  title: 'LIC. JOSÉ JUAN GONZÁLEZ VELÁZQUEZ',
                  image: Abogado5,
                  short_description: `Abogado`,
                  description: `<ul>
                  <li>Licenciado en Derecho egresado por la Universidad Autónoma de México</li>
                  <li>Abogado de recuperación de cartera en Mendiolea Vega Abogados, S.C.</li>
                  <li>Revisor de testimonios en Notaría Publica No. 233</li>
                  <li>Asociado Sr. De Litigio en Marvin, González Graf y González Larrazolo, S.C.</li>
                  <li>Gerente Jurídico en Grupo Carolo.</li>
                  <li>Abogado Laboral en Cervantes Nieto Rebolledo y Abogados, S.C. </li>
                  </ul>`,
                  quote: `"Los limites, como los miedos, la mayor parte del tiempo, son una ilusión."`,
                  component: 'lawyers',
                }}
              />
            </div>
            <div className="col-md-4">
              <HomeServices
                selectService={selectAbogado}
                content={{
                  title: 'LIC. SERGIO RUIZ LÓPEZ',
                  image: Abogado2,
                  short_description: `Abogado`,
                  description: `<ul>
                  <li>Licenciado en Derecho egresado por la Universidad Autónoma de Nuevo León </li>
                  <li>I Diplomado en derecho procesal Constitucional 2006, Suprema Corte de Justicia de la Nación en conjunto a la Universidad Autónoma de Nuevo León.</li>
                  <li>II Diplomado en derecho procesal Constitucional 2006, Suprema Corte de Justicia de la Nación en conjunto a la Universidad Autónoma de Nuevo León </li>
                  <li>Abogado Laboral en CFE División de Distribución Golfo Norte.</li>
                  <li>Abogado Asistente en Revisión en TIME UP REAL ESTATE LLC</li>
                  <li>Abogado Laboral en Cervantes Nieto Rebolledo y Abogados, S.C. </li>
                  </ul>`,
                  quote: `"No tengas miedo de renuncia a lo bueno para ir por lo grandioso."`,
                  component: 'lawyers',
                }}
              />
            </div>
            <div className="col-md-4">
              <HomeServices
                selectService={selectAbogado}
                content={{
                  title: 'LIC. IVÁN ANTONIO AVALOS CHIMAL',
                  image: Abogado6,
                  short_description: `Abogado`,
                  description: `<ul>
                  <li>Licenciado en Derecho egresado por la Universidad Autónoma de México.</li>
                  <li>Curso Juicios Orales en Materia Civil, Mercantil, Familiar y Amparo Directo en el Instituto Nacional de Desarrollo Jurídico.</li>
                  <li>Curso Globalización, Delito y Exclusión Social una Correlación a Debate por el Instituto Nacional de Ciencias Penales.</li>
                  <li>Curso Implementación de la Reforma en Material de Justicia Laboral por el Congreso Nacional de Tribunales.</li>
                  <li>Servicio en Junta Local de Conciliación y Arbitraje de la Ciudad de México.</li>
                  <li>Meritorio en Junta Local de Conciliación y Arbitraje de la Ciudad de México.</li>
                  <li>Licenciado en Derecho en Cervantes Nieto Rebolledo y Abogados, S.C. </li>
                  </ul>`,
                  quote: `"La llave del éxito se construye mediante tres valores; constancia, pasión y dedicación."`,
                  component: 'lawyers',
                }}
              />
            </div>
          </div>

          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <HomeServices
                selectService={selectAbogado}
                content={{
                  title: 'LIC. MIRIAM ARANZAZÚ CASTILLO GONZÁLEZ',
                  image: Abogado8,
                  short_description: `Abogado`,
                  description: `
                  <ul>
                    <li>
                    <b>SECRETARÍA DE RELACIONES EXTERIORES. CONSULTORÍA JURÍDICA</b>: Desempeñado con el equipo de trabajo del Consultor Jurídico, Alejandro Alday González, mismo que me permitió colaborar con las áreas de la Consultoría Jurídica, destacando la Dirección de Litigios I, Dirección de Litigios II y Dirección de Tratados III. </li>

                    <li>Brindar consultoría en aspectos competentes con la Secretaría a nivel internacional, revisión de contratos y memorandas de entendimiento de las Embajadas y Consulados de México en el exterior, realizar notas diplomáticas, así como principal apoyo en el litigio internacional, fijando como meta principal, la protección de los connacionales en el exterior.</li>

                    <li><b>DIRECCIÓN DE LITIGIOS I</b> :  Auxiliar en la atención a demandas laborales presentadas contra las Embajadas y Consulados de México en el exterior y Embajadas y Consulados de otros países en México.</li>
                  
                    <li>Realización de actividades, consultas e investigaciones gubernamentales relacionadas con la Convención de Viena sobre Relaciones Diplomáticas y Convención de Viena sobre Relaciones Consulares, migración y relación México-EUA, en coordinación con el Consultor jurídico Alejandro Celorio Alcantara Alcántara.
                    </li>
                  </ul>
                  `,
                  quote: null,
                  component: 'lawyers',
                }}
              />
            </div>

            <div className="col-md-4">
              <HomeServices
                selectService={selectAbogado}
                content={{
                  title: 'LIC. MARTIN ISMAEL REYES DARIO',
                  image: Abogado9,
                  short_description: `Abogado`,
                  description: null,
                  quote: null,
                  component: 'lawyers',
                }}
              />
            </div>

            <div className="col-md-4">
              <HomeServices
                selectService={selectAbogado}
                content={{
                  title: 'LIC. MANUEL SOSA ELIZAGA',
                  image: Abogado11,
                  short_description: `Abogado`,
                  description: null,
                  quote: null,
                  component: 'lawyers',
                }}
              />
            </div>
          </div>

          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <HomeServices
                selectService={selectAbogado}
                content={{
                  title: 'LIC. ALEXIS AZELL CLAVEL MUNGUIA',
                  image: Abogado10,
                  short_description: `Abogado`,
                  description: null,
                  quote: null,
                  component: 'lawyers',
                }}
              />
            </div>
          </div>

          <h1>PASANTES</h1>
          <div className="row justify-content-md-center">
            <div className="col-md-4">
              <HomeServices
                selectService={selectAbogado}
                content={{
                  title: 'DIANA CASTILLO PÉREZ',
                  image: Abogado1,
                  short_description: `Pasante`,
                  description: `<ul>
                  <li>Licenciada en Derecho egresada por la Universidad Autónoma de México</li>
                  <li>Curso Ley Federal del Trabajo por el Instituto Superior de Estudios Fiscales, S.C.</li>
                  <li>Curso Nuevo Procedimiento Laboral ante los tribunales de Trabajo, por el Instituto Superior de Estudios Fiscales, S.C</li>
                  <li>Curso Actualización en materia de Justicia Laboral, por la suprema Corte de Justicia de la Nación.</li>
                  <li>Curso General de Propiedad Intelectual, por la Organización Mundial de la Propiedad Intelectual.</li>
                  <li>Curso La Importancia de la Jurisprudencia, su conocimiento y su difusión, por la Suprema Corte de Justicia de la Nación. </li>
                  <li>Curso COVID-19, Enfermedad de Trabajo, por la Asociación Nacional de Abogados de Empresas. </li>
                  <li>Pasante en Derecho en Suarez y Cortes Abogados, S.C.</li>
                  <li>Pasante de Derecho en Cervantes Nieto Rebolledo y Abogados, S.C.</li>
                  </ul>`,
                  quote: `"El éxito es la suma de pequeños esfuerzos repetidos día tras día"`,
                  component: 'lawyers',
                }}
              />
            </div>
            <div className="col-md-4">
              <HomeServices
                selectService={selectAbogado}
                content={{
                  title: 'LUIS ALBERTO MENA MENA',
                  image: Abogado3,
                  short_description: `Pasante`,
                  description: `<ul>
                  <li>Licenciado en Derecho egresado por la Universidad Autónoma de México.</li>
                  <li>Diplomado en Derecho Empresarial y Corporativo, en la Facultad de Estudios Superiores Aragón. (UNAM)</li>
                  <li>Servicio en Ferrocarriles Nacionales de México en liquidación coordinación de lo contencioso Laboral. </li>
                  <li>Pasante en Derecho en Muñoz de Cote Abogados, S.C., firma especializada en Derecho Laboral</li>
                  <li>Pasante en Derecho en Cervantes Nieto Rebolledo y Abogados, S.C. </li>
                  </ul>`,
                  quote: `"La fórmula secreta para el éxito es; preparación, trabajo duro y aprender de los errores."`,
                  component: 'lawyers',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Abogados;
