import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../../public/images/home/CNR_logo.png';

function Heading(props) {
  return (
    <nav className="navbar navbar-expand-lg fixed-top navbar-dark">
      <div className="container-fluid d-flex">
        <NavLink exact activeClassName="active" className="navbar-brand p-2 flex-grow-1" to="/">
          <img src={logo} alt="" className="d-inline-block align-text-top" />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav d-flex flex-grow-1">
            <NavLink exact activeClassName="active" className="nav-link p-2 flex-grow-1" to="/">
              <span className="first-letter">Á</span>reas
              <div className="active-link width-100-transition"></div>
            </NavLink>
            <NavLink
              activeClassName="active"
              className="nav-link p-2 flex-grow-1"
              to="/capacitaciones"
            >
              <span className="first-letter">C</span>apacitaciones
              <div className="active-link width-100-transition"></div>
            </NavLink>
            <NavLink activeClassName="active" className="nav-link p-2 flex-grow-1" to="/abogados">
              <span className="first-letter">A</span>bogados
              <div className="active-link width-100-transition"></div>
            </NavLink>
            <a
              className="nav-link p-2 flex-grow-1"
              tabIndex="-1"
              aria-disabled="true"
              href="#contacto"
            >
              <span className="first-letter">C</span>ontacto
              <div className="active-link width-100-transition"></div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Heading;
