import React, { useEffect, useState } from 'react';

import close from '../../public/images/home/close.svg';

export default function HomeServiceDetail(props) {
  const [service, setHomeServiceDetail] = useState(props.service);
  useEffect(() => {
    setHomeServiceDetail(() => {
      return props.service;
    });
  }, [props.service]);

  return (
    <div>
      {service && (
        <div
          className={
            service
              ? 'service-detail-container cover-background parallax open'
              : 'service-detail-container cover-background parallax'
          }
          style={{
            backgroundImage: `url(${service.image})`,
          }}
        >
          <div className="service-detail-indications">
            <div className="close-cover transparent">
              <img
                className="close_icon"
                src={close}
                alt="Close Icon"
                aria-hidden="true"
                onClick={props.selectService(null)}
              />
            </div>
            <div className="text-over-transparent container">
              <div className="row title">
                <div className="col-md-12">
                  <p className="position-absolute top-50 start-50 translate-middle">
                    {service.title}
                  </p>
                </div>
              </div>
            </div>
            <div className="long-description text-over-transparent container">
              <div className="row title">
                <div className="col-md-12">
                  <p className="" dangerouslySetInnerHTML={{ __html: service.description }}></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
